import "../assets/css/app.min.css";

import React, { useState, useEffect, Fragment } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { IntlProvider } from "react-intl";
import translation from "./translation";
import Loader from "./utils/Loader";
import endpoint from "../api";
import OneSignal from "react-onesignal";
import DynamicTitle from './utils/DynamicTitle';
import pageRoutes from './utils/PageRoutes';

export const AppContext = React.createContext();

const LANGUAGE_KEY = "savermiddleeast.language";
const CURRENCY_KEY = "savermiddleeast.currency";
const selectedSite = 2;

const offlineLanguage = [{ label: "English", value: "EN", currency: "USD" }];

export default function App({ location }) {
  const [languages, setLanguages] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [languageCode, setLanguageCode] = useState();
  const [loading, setLoading] = useState(true);

  const AppContextValue = {
    handleCurrencyChange,
    handleLanguageChange,
    currency,
    languages,
    selectedLanguage,
    selectedSite,
  };
  
  useEffect(() => {
    endpoint
      .get(`/languages/`)
      .then((response) => {
        setLanguages(response.data);
      })
      .catch((err) => {
        setLanguages(offlineLanguage);
      });

    const currency = localStorage.getItem(CURRENCY_KEY);
    if (currency != null) setCurrency(currency);
  }, []);

  useEffect(() => {
    if (languages.length) {
      localStorage.setItem(LANGUAGE_KEY, JSON.stringify(selectedLanguage));
      setLanguageCode(selectedLanguage.value);
      setCurrency(selectedLanguage.currency);
    }

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  }, [selectedLanguage]);

  useEffect(() => {
    const languageJSON = localStorage.getItem(LANGUAGE_KEY);

    if (languages.length) {
      if (languageJSON != null) {
        let languageObject = JSON.parse(languageJSON);
        setSelectedLanguage(languageObject);
      } else {
        let defaultLanguage = languages.find(() => true);
        setSelectedLanguage(defaultLanguage);
      }
    }
  }, [languages]);

  useEffect(() => {
    currency && localStorage.setItem(CURRENCY_KEY, currency);
  }, [currency]);

  useEffect(() => {
    document.body.className = "";

    if (languageCode != undefined) {
      document.body.classList.add(languageCode.toLowerCase());
    }
  }, [languageCode]);

  function handleLanguageChange(language) {
    setSelectedLanguage(language.find(() => true));
  }

  function handleCurrencyChange(newCurrency) {
    setCurrency(newCurrency);
  }

  useEffect(() => {
    if (languageCode != undefined) {
      OneSignal.init({
        appId: "b4ee639c-44f9-4391-b32a-95b6fb2f5c40",
        safari_web_id:
          "web.onesignal.auto.2d55a779-27e1-4bb7-a896-7e19803bedb7",
        allowLocalhostAsSecureOrigin: true,
        promptOptions: {
          actionMessage:
            translation[languageCode]["onesignal.promptOptions.actionMessage"],
          exampleNotificationTitleDesktop:
            translation[languageCode][
              "onesignal.promptOptions.exampleNotificationTitleDesktop"
            ],
          exampleNotificationMessageDesktop:
            translation[languageCode][
              "onesignal.promptOptions.exampleNotificationMessageDesktop"
            ],
          exampleNotificationTitleMobile:
            translation[languageCode][
              "onesignal.promptOptions.exampleNotificationTitleMobile"
            ],
          exampleNotificationMessageMobile:
            translation[languageCode][
              "onesignal.promptOptions.exampleNotificationMessageMobile"
            ],
          exampleNotificationCaption:
            translation[languageCode][
              "onesignal.promptOptions.exampleNotificationCaption"
            ],
          acceptButton:
            translation[languageCode]["onesignal.promptOptions.acceptButton"],
          acceptButtonText:
            translation[languageCode][
              "onesignal.promptOptions.acceptButtonText"
            ],
          cancelButton:
            translation[languageCode]["onesignal.promptOptions.cancelButton"],
          cancelButtonText:
            translation[languageCode][
              "onesignal.promptOptions.cancelButtonText"
            ],
        },
        welcomeNotification: {
          title:
            translation[languageCode]["onesignal.welcomeNotification.title"],
          message:
            translation[languageCode]["onesignal.welcomeNotification.message"],
        },
        notifyButton: {
          text: {
            tipStateUnsubscribed:
              translation[languageCode][
                "onesignal.notifyButton.text.tipStateUnsubscribed"
              ],
            tipStateSubscribed:
              translation[languageCode][
                "onesignal.notifyButton.text.tipStateSubscribed"
              ],
            tipStateBlocked:
              translation[languageCode][
                "onesignal.notifyButton.text.tipStateBlocked"
              ],
            messageActionSubscribed:
              translation[languageCode][
                "onesignal.notifyButton.text.messageActionSubscribed"
              ],
            messageActionUnsubscribed:
              translation[languageCode][
                "onesignal.notifyButton.text.messageActionUnsubscribed"
              ],
            dialogMainTitle:
              translation[languageCode][
                "onesignal.notifyButton.text.dialogMainTitle"
              ],
            dialogMainButtonSubscribe:
              translation[languageCode][
                "onesignal.notifyButton.text.dialogMainButtonSubscribe"
              ],
            dialogMainButtonUnsubscribe:
              translation[languageCode][
                "onesignal.notifyButton.text.dialogMainButtonUnsubscribe"
              ],
            dialogBlockedTitle:
              translation[languageCode][
                "onesignal.notifyButton.text.dialogBlockedTitle"
              ],
            dialogBlockedMessage:
              translation[languageCode][
                "onesignal.notifyButton.text.dialogBlockedMessage"
              ],
          },
        },
      });
      OneSignal.setDefaultTitle("SaverMiddleeast");
    }
  }, [languageCode]);

  return (
    <>
      <DynamicTitle />
      {!languageCode && <Loader />}
      {languageCode && (
        <IntlProvider
          locale={languageCode}
          messages={translation[languageCode]}
          textComponent={Fragment}
        >
          <AppContext.Provider value={AppContextValue}>
            <div className="app-container">
              {loading && <Loader />}
              {!loading && (
                <Switch>
                  {pageRoutes.map(({ path, exact, component: Component }, index) => (
                    <Route key={index} path={path} exact={exact} component={Component} />
                  ))}
                </Switch>
              )}
            </div>
          </AppContext.Provider>
        </IntlProvider>
      )}
    </>
  );
}
